@import '../../styles/scss/_variables.scss';

.layout {
    min-height: 100vh;
    padding: 0;

    .main {
        min-height: calc(100vh - 2.5rem);
        transition: margin-left 0.5s;
        position: relative;
        z-index: 0;
        padding: 1rem;
        background-color: $primary;
        color: #fff;

        pre {
            margin: 0;
            padding: 0;
            color: #fff;

            > a {
                // color: $success;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
