@import '../../styles/scss/_variables.scss';

.menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    pointer-events: none;
    z-index: 1;
    padding-top: 4rem;
    background-color: $primary-dark;
    border-right: 0.1rem solid $primary-border;
    width: 20rem;
    transform: translateX(-103%);
}
