@import '../../styles/scss/_variables.scss';

.tabs {
    position: sticky;
    top: 0;
    z-index: 2;

    button {
        border: none;
        border-radius: 0;
        height: auto;
        border-bottom: 1px solid $primary-border;
        padding: 0 1rem;

        &:focus,
        &.is-focused {
            border: none;
            border-radius: 0;
            height: auto;
            border-bottom: 1px solid $primary-border;
        }

        &:focus:not(:active),
        &.is-focused:not(:active) {
            box-shadow: none;
        }

        &.menuBtn {
            position: sticky;
            left: 0;
            background-color: $primary-dark !important;
            cursor: pointer;
            border-bottom: 0.1rem solid $primary-border !important;

            .menu {
                cursor: pointer;
                color: #fff;
                padding: 0 1rem;
                margin: 0 !important;
                width: 3rem;

                // i {
                //     color: $no-3;

                //     &:hover {
                //         color: $gray-100;
                //     }
                // }

                > svg {
                    width: 3rem;
                    height: 3rem;
                    color: $no-3;

                    &:hover {
                        color: $gray-100;
                    }
                }
            }

            &:after {
                box-shadow: 1rem 0 1rem -1rem $primary-dark inset;
                content: ' ';
                height: 100%;
                position: absolute;
                top: 0;
                right: -1rem;
                width: 1rem;
            }

            &:hover {
                border-bottom: 1px solid $primary-border;
                color: #fff;

                i {
                    color: $gray-100;
                }
            }
        }

        &.resetBtn {
            background-color: $primary-dark !important;
            padding: 0;
            text-align: right;
            border-bottom: 0.1rem solid $primary-border !important;
            width: 3rem;
            // height: auto;

            // > span {
            //     margin-left: 1.5em !important;
            //     margin-right: 0.5em !important;
            //     cursor: pointer;

            //     i {
            //         color: $gray-700;

            //         &:hover {
            //             color: $no-5;
            //         }
            //     }
            // }

            > svg {
                width: 3rem;
                height: 3rem;
                color: $gray-700;
                margin-right: 0.5rem;
                margin-left: 1rem;

                &:hover {
                    color: $no-5;
                }
            }

            &:hover {
                border-bottom: 1px solid $primary-border;
                color: #fff;

                i {
                    color: $no-5;
                }
            }
        }
    }

    ul {
        height: 3.1rem;

        > li {
            // padding-right: 1rem;
            height: 100%;

            > a {
                display: flex;
                align-items: center;
                padding-right: 0.5rem !important;
                height: 100%;
            }

            .close {
                // font-size: 0.75rem;
                // line-height: 1;
                margin-right: 0 !important;
                margin-left: 1rem !important;

                // > i {
                //     color: $gray-500;

                //     &:hover {
                //         color: $no-3;
                //     }
                // }

                > svg {
                    width: 3rem;
                    height: 3rem;
                    color: $gray-500;

                    &:hover {
                        color: $no-3;
                    }
                }
            }
        }
    }
}
